// export const environment = {
//   amplify: {
//     Auth: {
//       region: 'eu-west-1',
//       userPoolId: 'eu-west-1_p9wGuji8j',
//       userPoolWebClientId: '7q4jktvi20q4e0mb1nsni3b9qf',
//       oauth: {
//         domain: 'dcycle-backend-dacuna97-dev-azure.auth.eu-west-1.amazoncognito.com',
//         scope: ['email', 'openid'],
//         redirectSignIn: 'https://delight-app.dcycle.io/callback',
//         redirectSignOut: 'https://delight-app.dcycle.io/',
//         responseType: 'code'
//       }
//     }
//   },
//   backendUrl: 'https://baje18ayyb.execute-api.eu-west-1.amazonaws.com/dev',
//   ldClientSideID: '63e0c1ec62e30213915d1fd6',
//   typeformEventUrl: {
//     en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
//     pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
//     es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
//   },
//   clientUrl: 'http://localhost:3000/'
// };

export const environment = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_w9xh0N4bp',
      userPoolWebClientId: '57hnotp28565r098ho4osvkg05',
      oauth: {
        domain: 'dcycle-backend-auth-pato-dev-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://delight-app.dcycle.io/callback',
        redirectSignOut: 'https://delight-app.dcycle.io/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://api.pato-dev.dcycle.io',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'https://delight-app.dcycle.io/'
};
